import { faPlus, faSync, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import AddNotificationEmailModal from "../../../components/admin/AddNotificationEmailModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import InformationModal from "../../../components/InformationModal";

import Loader from "../../../components/Loader";

import { notificationApi } from "../../../services/notificationServices";
import { useAuth } from "../../../providers/authProvider";
import { utilsHelper } from "../../../helpers/utilsHelper";
import AdvanceTablePagination from "../../../components/advanceTable/AdvanceTablePagination";

const SafetyNotifications = () => {
  const [authContext] = useAuth();
  const [loading, setLoading] = useState(true);
  const [safetyNotifications, setSafetyNotifications] = useState([]);
  const [refresh, setRefresh] = useState();
  const [addModal, setAddModal] = useState();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initialState = {
    sizePerPage: 15,
    page: 1,
    sortBy: "email",
    direction: "asc",
  };

  const [state, setState] = useState(initialState);

  const condition = useMemo(
    () => ({
      page: state.page - 1,
      pageSize: state.sizePerPage,
      sortBy: state.sortBy,
      direction: state.direction,
      jobSourceId: authContext.currentUser.jobSourceId,
    }),
    [state, authContext.currentUser.jobSourceId]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await notificationApi.getSafetyNotifications(condition);
        setSafetyNotifications(result);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchData();
  }, [refresh, condition]);

  const handlePageChange = useCallback((newPage) => {
    setState((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  }, []);

  const handlePageSizeChange = useCallback((newSize) => {
    setState((prevState) => ({
      ...prevState,
      sizePerPage: newSize,
      page: 1,
    }));
  }, []);

  const removeEmail = (safetyNotification) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        try {
          await notificationApi.deleteSafetyNotification(safetyNotification);
          setLoading(false);
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Remove Email Notification",
            body: "Email removed successfully.",
            onClose: () => {
              setInformationModal({ isOpen: false, title: "", body: "" });
              setRefresh(!refresh);
            },
          });
        } catch (err) {
          setConfirmationModal(initConfirmationModal);
          setLoading(false);
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Email Notification",
      body: `<span class="text-center">Do you confirm you want to remove ${safetyNotification?.email}?</span>
            <br/>This email will be removed from the Job Source Key <strong>${safetyNotification?.jobSource?.sourceKey}</strong>`,
    });
  };

  const onCreate = () => {
    setAddModal(true);
  };

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Safety Notifications</h3>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              size="sm"
              className="mr-3 rounded-circle d-flex custom-rounded-button"
              color="primary"
              onClick={() => setRefresh(!refresh)}
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
            {!utilsHelper.isReadOnly(authContext) ? (
              <Button
                size="sm"
                className="rounded-circle d-flex custom-rounded-button"
                color="primary"
                onClick={onCreate}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            ) : null}
          </div>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Loader />
          ) : safetyNotifications?.data.length ? (
            <ListGroup flush className="border-top">
              <ListGroupItem
                className="py-2 border-bottom font-weight-bold bg-lighter text-muted small"
                tag="div"
              >
                <div className="row">
                  <div className="col-4">
                    <span>Email</span>
                  </div>
                  <div className="col-5 text-center">
                    <span>Job Source Key</span>
                  </div>
                </div>
              </ListGroupItem>
              {(safetyNotifications?.data || []).map(
                (safetyNotification, index) => (
                  <ListGroupItem
                    key={index}
                    className="py-2 border-bottom d-flex justify-content-between align-items-center"
                    tag="div"
                  >
                    <div className="row w-100">
                      <div className="col-4">
                        <span>{safetyNotification.email}</span>
                      </div>
                      <div className="col-5 text-center">
                        <span>{safetyNotification?.jobSource?.sourceKey}</span>
                      </div>
                      <div className="col-3 d-flex justify-content-end">
                        <Button
                          className="ml-2 rounded d-flex align-items-center"
                          color="none"
                          size="sm"
                          onClick={() => removeEmail(safetyNotification)}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="text-danger"
                            size="lg"
                          />
                        </Button>
                      </div>
                    </div>
                  </ListGroupItem>
                )
              )}
              <AdvanceTablePagination
                totalCount={safetyNotifications?.count || 0}
                pageCount={safetyNotifications?.totalPages || 0}
                currentPage={condition?.page}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                pageSize={state?.sizePerPage}
                className="mt-3"
              />
            </ListGroup>
          ) : (
            <div className="text-center">No emails added</div>
          )}
        </CardBody>
      </Card>
      {addModal ? (
        <AddNotificationEmailModal
          submitFunction={notificationApi.createSafetyNotification}
          onSubmit={() => {
            setAddModal();
            setInformationModal({
              isOpen: true,
              title: "Add Email Notification",
              body: "Email added successfully.",
            });
            setRefresh(!refresh);
          }}
          onClose={() => setAddModal()}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default SafetyNotifications;
