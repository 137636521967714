import {
  faEnvelope,
  faPaperPlane,
  faSave,
  faDownload,
  faUpload,
  faTimesCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { changeOrdersApi } from "../../services/changeOrderServices";
import { awsApi } from "../../services/awsServices";
import config from "../../config/config";
import { utilsHelper } from "../../helpers/utilsHelper";
import Loader from "../Loader";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../providers/workOrderDetailsProvider";
import InformationModal from "../InformationModal";
import DocumentPreviewModal from "./DocumentPreviewModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import ContactModal from "../ContactModal";
import { useAuth } from "../../providers/authProvider";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { changeOrderHelper } from "../../helpers/changeOrderHelper";
import moment from "moment";
import CurrencyInput from "react-currency-input-field";

const COMPANY_APC = "APC";
const REGULAR = "regular";

const STATUS_INVOICED = 6;

const ChangeOrderForm = ({ changeOrder }) => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const IS_READ_ONLY = workOrderDetails.workOrder?.statusId === STATUS_INVOICED;

  const badgeStatusColor = `float-right badge badge-${
    changeOrderHelper.COLORS[changeOrder.statusId]
  }`;

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [showContactModal, setContactModal] = useState({
    isOpen: false,
    isEmail: false,
    employees: null,
    supervisor: null,
    defaultRecipients: [],
  });

  const [purchasingContactEmail, setPurchasingContactEmail] = useState("");
  const [purchasingContactName, setPurchasingContactName] = useState("");
  const [requestedBy, setRequestedBy] = useState("");
  const [description, setDescription] = useState("");
  const [purchasingContactPhone, setPurchasingContactPhone] = useState("");
  const [customerPO, setCustomerPO] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [newTotal, setNewTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [changeOrderType, setChangeOrderType] = useState(
    changeOrderHelper.CHANGE_ORDER_TYPE.TIME_MATERIAL
  );

  const [loading, setLoading] = useState();
  const [pdfLoading, setPDFLoading] = useState();
  const [previewModal, setPreviewModal] = useState({});

  const [attachmentsInfo, setAttachmentsInfo] = useState([]);
  const [presignedPhotoUrl, setPresignedPhotoUrl] = useState();
  const hiddenFileInputAttachment = useRef(null);

  const onSubmit = async (e) => {
    const nameButtonSubmitted = e.nativeEvent.submitter.name;
    const statusId =
      nameButtonSubmitted === REGULAR
        ? changeOrderHelper.STATUS_IN_REVIEW
        : changeOrderHelper.STATUS_COMPLETED;
    e.preventDefault();

    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setLoading(true);
        const urlsAttacmments = await uploadAttachments();
        const allAttacmnets = mergeAttachments(Object.values(urlsAttacmments));
        const photo = photoUrl;

        await changeOrdersApi.updateChangeOrder({
          ...changeOrder,
          purchasingContactEmail: purchasingContactEmail,
          purchasingContactName: purchasingContactName,
          purchasingContactPhone: purchasingContactPhone,
          customerPO: customerPO,
          newDescription: newDescription,
          newTotal: newTotal,
          changeOrderType: changeOrderType,
          statusId: statusId,
          attachments: allAttacmnets,
          notes: notes,
          photoUrl: photo,
          description: description,
          total: total,
          requestedBy: requestedBy,
        });
        setConfirmationModal(initConfirmationModal);
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Change Order",
          body: "Change Order updated successfully",
          onClose: () =>
            setWorkOrderDetails({
              action: ACTIONS.REFRESH,
            }),
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
        setWorkOrderDetails({
          action: ACTIONS.REFRESH,
        });
      },
      title: "Update Change Order",
      body: `<div class="text-center">Are you sure you want to make changes to this change order?</div>`,
    });
  };

  const onDelete = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await changeOrdersApi.deleteChangeOrder(changeOrder.id);
        setConfirmationModal(initConfirmationModal);
        setInformationModal({
          isOpen: true,
          title: `Change Orders`,
          body: `Change order deleted successfully`,
          onClose: () => {
            setInformationModal({ isOpen: false, title: "", body: "" });
            setWorkOrderDetails({
              action: ACTIONS.REFRESH,
            });
          },
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
        setWorkOrderDetails({
          action: ACTIONS.REFRESH,
        });
      },
      title: "Delete Change Order",
      body: `<p class="text-center">Do you confirm you want to delete this Change Order?</p>`,
    });
  };

  const mergeAttachments = (urls) => {
    const oldAttachments = attachmentsInfo
      .filter((att) => att.id)
      .map((a) => {
        return {
          id: a.id,
          url: null,
        };
      });
    const newAttachments = urls.map((url) => {
      return {
        id: null,
        url,
      };
    });
    return [...oldAttachments, ...newAttachments];
  };

  const uploadAttachments = () =>
    new Promise((resolve, reject) => {
      try {
        const filteredAttachments = attachmentsInfo.filter(
          (att) => !att.id && !!att.file
        );

        const readyToSignDocuments = filteredAttachments.map((document) => {
          return {
            fileName: document.file.name,
            fileType: document.file.type,
            bucket: config.documentsBucket,
            method: "putObject",
          };
        });

        awsApi
          .signDocuments({ documents: readyToSignDocuments })
          .then((signedURLs) => {
            const elements = signedURLs
              .filter((signedURL) => signedURL.success)
              .map((signedURL) => {
                const file = filteredAttachments.find(
                  (document) => document.file.name === signedURL.fileName
                ).file;

                return file
                  ? {
                      url: signedURL.signedRequest,
                      file,
                      fileUrl: signedURL.url,
                    }
                  : null;
              });
            const promises = elements.map((element) =>
              awsApi.putDocumentsToS3(element, element.file.type)
            );
            Promise.all(promises).then((result) => {
              const urls = result.reduce((p, c) => {
                p[c.name] = c.url;
                return p;
              }, {});
              resolve(urls);
            });
          });
      } catch (error) {
        reject(error);
      }
    });

  const onDownloadPDF = async (e) => {
    setPDFLoading(true);
    changeOrdersApi
      .getChangeOrderPDF({
        id: changeOrder.id,
        browserTimeOffset: moment().utcOffset() / 60,
      })
      .then((res) => {
        const data = new Blob([res], { type: "application/pdf" });
        const URL = window.URL.createObjectURL(data);
        setPDFLoading(false);
        return window.open(URL, "new");
      })
      .catch((err) => {
        setPDFLoading(false);
      });
  };

  const onEmailCustomer = async (e) => {
    e.preventDefault();
    setContactModal({
      isOpen: true,
      isEmail: true,
      defaultRecipients: [],
    });
  };

  const getPresignedUrl = async (fileUrl) => {
    const dividedUrl = fileUrl.split("/");
    const fileName = dividedUrl[dividedUrl.length - 1];
    const dividedName = fileName.split(".");
    const fileType = dividedName[dividedName.length - 1];
    const presingned = await awsApi.signDocuments({
      documents: [
        {
          fileName,
          fileType,
          bucket: config.documentsBucket,
          method: "getObject",
        },
      ],
    });
    const url = presingned?.pop().signedRequest;
    return url;
  };

  const setAttachmentsWithPresignedUrls = useCallback(async (attachments) => {
    const presignedAttachments = [];
    for (const attachment of attachments) {
      presignedAttachments.push({
        id: attachment.id,
        attachmentUrl: await getPresignedUrl(attachment.attachmentUrl),
        file: null,
      });
    }

    setAttachmentsInfo(presignedAttachments);
  }, []);

  useEffect(() => {
    if (changeOrder) {
      setPurchasingContactEmail(changeOrder.purchasingContactEmail);
      setPurchasingContactName(changeOrder.purchasingContactName);
      setRequestedBy(changeOrder.requestedBy);
      setDescription(changeOrder.description);
      setTotal(changeOrder.total);
      setPurchasingContactPhone(changeOrder.purchasingContactPhone);
      setCustomerPO(changeOrder.customerPO);
      setChangeOrderType(changeOrder.changeOrderType);
      setNewTotal(changeOrder.newTotal);
      setNewDescription(changeOrder.newDescription);
      setNotes(changeOrder.notes);
      setPhotoUrl(changeOrder.photoUrl);
      if (changeOrder.changeOrderAttachments.length > 0) {
        setAttachmentsWithPresignedUrls(changeOrder.changeOrderAttachments);
      } else {
        setAttachmentsInfo([]);
      }
      if (changeOrder.photoUrl) {
        getPresignedUrl(changeOrder.photoUrl).then((presigned) => {
          setPresignedPhotoUrl(presigned);
        });
      }
    }
  }, [changeOrder, setAttachmentsWithPresignedUrls]);

  const handleClickInputAttachments = (event) => {
    hiddenFileInputAttachment.current.click();
  };

  const loadAttachments = async (event) => {
    const files = event.target.files;
    const mappedFiles = await Promise.all(
      Array.from(files).map(
        (file) =>
          new Promise((resolve, reject) => {
            const oFReader = new FileReader();
            oFReader.readAsDataURL(file);

            oFReader.onload = (oFREvent) => {
              resolve({
                id: null,
                file,
                attachmentUrl: oFREvent.target.result,
              });
            };

            oFReader.onerror = (error) => reject(error);
          })
      )
    );

    setAttachmentsInfo((prev) => [...prev, ...mappedFiles]);
    event.target.value = null;
  };

  const removeAttachment = (url) => {
    setAttachmentsInfo(
      attachmentsInfo.filter((att) => att.attachmentUrl !== url)
    );
  };

  const openImageModal = (url) => {
    return setInformationModal({
      isOpen: true,
      title: "Image Preview",
      rawBody: true,
      body: (
        <>
          <div className="d-flex flex-column text-center">
            <TransformWrapper initialScale={1} minScale={0.5} maxScale={3}>
              {({ zoomIn, zoomOut, ...rest }) => (
                <React.Fragment>
                  <div
                    style={{ position: "absolute", zIndex: 100 }}
                    className="tools"
                  >
                    <Button
                      className="rounded btn-light border"
                      onClick={() => {
                        utilsHelper.downloadFile(url);
                      }}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                    <Button
                      className="rounded mx-2 btn-light border"
                      onClick={() => zoomOut()}
                    >
                      -
                    </Button>
                    <Button
                      className="rounded btn-light border"
                      onClick={() => zoomIn()}
                    >
                      +
                    </Button>
                  </div>
                  <TransformComponent>
                    <img className="img-fluid rounded" src={url} alt="" />
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>
        </>
      ),
    });
  };

  return (
    <div>
      {loading ? (
        <Loader size="sm" />
      ) : (
        <Form onSubmit={onSubmit}>
          <div className="border-bottom pb-2">
            <div className="d-flex justify-content-between align-items-center my-3">
              <div className="d-flex flex-column align-items-start">
                <h4>{`Submitted by: ${changeOrder.user.firstName} ${changeOrder.user.lastName}`}</h4>
                <span className={badgeStatusColor}>
                  {changeOrderHelper.STATUS_LABEL[changeOrder.statusId]}
                </span>
              </div>
              <div className="d-flex">
                {!utilsHelper.isReadOnly(authContext) ? (
                  <Button
                    size="sm"
                    className="rounded d-flex align-items-center flex-shrink-0"
                    color="secondary"
                    onClick={onEmailCustomer}
                  >
                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                    Email Customer
                  </Button>
                ) : null}
                {pdfLoading ? (
                  <Loader size="sm" className="mx-2 min-width-50" />
                ) : (
                  <Button
                    size="sm"
                    className="mx-2 rounded d-flex align-items-center"
                    color="info"
                    onClick={onDownloadPDF}
                  >
                    <FontAwesomeIcon icon={faDownload} className="mr-2" />
                    PDF
                  </Button>
                )}
                {!utilsHelper.isReadOnly(authContext) ? (
                  <Button
                    size="sm"
                    className="rounded d-flex align-items-center flex-shrink-0"
                    color="danger"
                    onClick={() => onDelete()}
                  >
                    <FontAwesomeIcon icon={faTrash} className="mr-2" />
                    <span>Delete</span>
                  </Button>
                ) : null}
              </div>
            </div>
            <FormGroup row>
              <Label sm={3} className="text-sm-left">
                Description
              </Label>
              <Col sm={9}>
                <Input
                  type="textarea"
                  rows={4}
                  maxLength="255"
                  placeholder="Enter the description..."
                  value={description || ""}
                  onChange={(event) =>
                    setDescription(event.currentTarget.value)
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3} className="text-sm-left">
                Total
              </Label>
              <Col sm={9}>
                <CurrencyInput
                  intlConfig={{ locale: "en-US", currency: "USD" }}
                  disabled={IS_READ_ONLY}
                  maxLength={10}
                  step={0.5}
                  min={0}
                  className="my-2 form-control"
                  placeholder="Enter quote details here..."
                  value={total || ""}
                  onValueChange={(value) => setTotal(value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3} className="text-sm-left">
                Requested by
              </Label>
              <Col sm={9}>
                <Input
                  invalid={!requestedBy}
                  maxLength="60"
                  className="my-2"
                  type="text"
                  placeholder="Enter the requested by name..."
                  value={requestedBy || ""}
                  onChange={(event) =>
                    setRequestedBy(event.currentTarget.value)
                  }
                  required
                />
              </Col>
            </FormGroup>
          </div>
          <div className="border-bottom py-3">
            <h4 className="font-weight-bold mb-3">Quote Information</h4>
            <FormGroup row>
              <Label sm={3} className="text-sm-left">
                Purchasing Contact
              </Label>
              <Col sm={9}>
                <Input
                  maxLength="60"
                  className="col-4"
                  type="text"
                  placeholder="Enter the contact name..."
                  value={purchasingContactName || ""}
                  onChange={(event) =>
                    setPurchasingContactName(event.currentTarget.value)
                  }
                />
                <Input
                  maxLength="60"
                  type="text"
                  className="col-4 my-2"
                  placeholder="Enter the contact phone..."
                  value={purchasingContactPhone || ""}
                  onChange={(event) =>
                    setPurchasingContactPhone(event.currentTarget.value)
                  }
                />
                <Input
                  maxLength="60"
                  type="email"
                  className="col-4"
                  placeholder="Enter the contact email..."
                  value={purchasingContactEmail || ""}
                  onChange={(event) =>
                    setPurchasingContactEmail(event.currentTarget.value.trim())
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3} className="text-sm-left">
                Customer PO#
              </Label>
              <Col sm={9}>
                <Input
                  maxLength="21"
                  type="text"
                  className="my-2"
                  placeholder="Enter the customer PO number..."
                  value={customerPO || ""}
                  onChange={(event) => setCustomerPO(event.currentTarget.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3} className="text-sm-left">
                PWO Number
              </Label>
              <Col sm={9}>
                <Input
                  className="my-2"
                  value={workOrderDetails?.workOrder?.workOrderNumber || ""}
                  disabled
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3} className="text-sm-left">
                Description of Change
              </Label>
              <Col sm={9}>
                <Input
                  className="my-2"
                  type="textarea"
                  rows={4}
                  placeholder="Enter quote details here..."
                  value={newDescription || ""}
                  onChange={(event) =>
                    setNewDescription(event.currentTarget.value)
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3} className="text-sm-left my-2">
                Job type
              </Label>
              <Col sm={9} className="my-2">
                <CustomInput
                  required
                  id="jobTypeSelect"
                  type="select"
                  name="jobTypeSelect"
                  onChange={(event) =>
                    setChangeOrderType(event.currentTarget.value)
                  }
                  value={changeOrderType || ""}
                >
                  <option value={changeOrderHelper.CHANGE_ORDER_TYPE.FIRM_BID}>
                    Firm Bid
                  </option>
                  <option
                    value={changeOrderHelper.CHANGE_ORDER_TYPE.TIME_MATERIAL}
                  >
                    Time & Material
                  </option>
                </CustomInput>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3} className="text-sm-left">
                Internal Notes
              </Label>
              <Col sm={9}>
                <Input
                  className="my-2"
                  maxLength="255"
                  type="textarea"
                  rows={4}
                  placeholder="Enter internal notes here..."
                  value={notes || ""}
                  onChange={(event) => setNotes(event.currentTarget.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3} className="text-sm-left">
                Total
              </Label>
              <Col sm={9}>
                <CurrencyInput
                  intlConfig={{ locale: "en-US", currency: "USD" }}
                  disabled={IS_READ_ONLY}
                  maxLength={10}
                  step={0.5}
                  min={0}
                  className="my-2 form-control"
                  placeholder="Enter quote details here..."
                  value={newTotal || ""}
                  onValueChange={(value) => setNewTotal(value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3} className="text-sm-left">
                Client Signature
              </Label>
              <Col sm={9} className="text-left">
                {changeOrder.signatureUrl ? (
                  <img
                    alt={"Signature"}
                    className="max-width-250"
                    src={changeOrder.signatureUrl}
                  />
                ) : (
                  <span>No signature provided</span>
                )}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3} className="text-sm-left">
                Attachments
              </Label>
              <Col
                sm={9}
                className={`${
                  attachmentsInfo.length === 0
                    ? "d-flex align-items-center"
                    : ""
                }`}
              >
                <input
                  ref={hiddenFileInputAttachment}
                  value=""
                  multiple={true}
                  type="file"
                  accept="image/*"
                  className="mb-1 text-truncate"
                  onChange={loadAttachments}
                  style={{ display: "none" }}
                />
                {!utilsHelper.isReadOnly(authContext) ? (
                  <Button
                    size="sm"
                    className="rounded mr-2"
                    onClick={handleClickInputAttachments}
                  >
                    <FontAwesomeIcon icon={faUpload} className="mr-2" />
                    Choose Files
                  </Button>
                ) : null}
                {attachmentsInfo.length > 0 || photoUrl ? (
                  <div className="d-flex flex-wrap mt-3">
                    {photoUrl ? (
                      <div
                        className="figure img-thumbnail cursor-pointer mx-1"
                        onClick={() => openImageModal(presignedPhotoUrl)}
                        style={{
                          backgroundPosition: "center",
                          backgroundImage: `url(${presignedPhotoUrl})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          cursor: "pointer",
                          alignSelf: "center",
                          height: "200px",
                          width: "200px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="ml-2 m-top cursor-pointer bg-white text-danger"
                          onClick={(e) => {
                            e.stopPropagation();
                            setPhotoUrl(null);
                          }}
                        />
                      </div>
                    ) : null}
                    {attachmentsInfo.map((att) => {
                      return (
                        <div
                          className="figure img-thumbnail cursor-pointer mx-1"
                          onClick={() => openImageModal(att.attachmentUrl)}
                          style={{
                            backgroundPosition: "center",
                            backgroundImage: `url(${att.attachmentUrl})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            cursor: "pointer",
                            alignSelf: "center",
                            height: "200px",
                            width: "200px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            className="ml-2 m-top cursor-pointer bg-white text-danger"
                            onClick={(e) => {
                              e.stopPropagation();
                              removeAttachment(att.attachmentUrl);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <span>No files attached</span>
                )}
              </Col>
            </FormGroup>
            <div className="d-flex justify-content-end my-3 pt-4 border-top">
              {!IS_READ_ONLY && (
                <Button
                  size="sm"
                  className="rounded"
                  color={"primary"}
                  type="submit"
                  name="regular"
                >
                  <FontAwesomeIcon icon={faSave} className="mr-2" />
                  Save
                </Button>
              )}
              {authContext.currentUser?.jobSource?.sourceKey === COMPANY_APC &&
              !IS_READ_ONLY ? (
                <Button
                  size="sm"
                  className="ml-2 rounded"
                  color={"primary"}
                  type="submit"
                  name="send_to_super"
                >
                  <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                  Save & Send to Super
                </Button>
              ) : null}
            </div>
          </div>
        </Form>
      )}
      {informationModal?.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  rawBody: false,
                  isOpen: false,
                  title: "",
                  body: "",
                })
          }
        />
      ) : showContactModal.isOpen ? (
        <ContactModal
          {...showContactModal}
          onClose={() =>
            setContactModal({
              isOpen: false,
              employees: null,
              supervisor: null,
            })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : previewModal.isOpen ? (
        <DocumentPreviewModal
          file={previewModal.file}
          onClose={() => setPreviewModal({ isOpen: false, file: null })}
        />
      ) : null}
    </div>
  );
};

export default ChangeOrderForm;
