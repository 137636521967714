import React from "react";
import { Input, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { DayPicker } from "react-day-picker";
import { useWorkOrderDetails } from "../../../../providers/workOrderDetailsProvider";
import DimensionsClockfaceAlignmentEntry from "./SpecificQuestionTypes/DimesionesClockfaceAlignmentEntry";
import Clockface from "./SpecificQuestionTypes/Clockface";

const MULTIPLE_CHOICE = 1;
const SHORT_TEXT = 2;
const LONG_TEXT = 3;
const NUMBER = 4;
const DATE = 5;
const CLOCKFACE = 7;
const CREW_LIST = 9;
const CLOCKFACE_ALIGNMENT_ENTRY = 10;
const DIMENSIONS_CLOCKFACE_ALIGNMENT_ENTRY = 11;
const AUTOPOPULATE_FORMULA = 15;

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const noOptions = [
  {
    value: null,
    label: `N/A`,
  },
];

const AnswerInput = ({
  inspectionQuestionType,
  question,
  inputValue,
  setInputValue,
  explanation,
  setExplanation,
}) => {
  const inspectionAnswer = question.inspectionAnswers[0];

  const [workOrderDetails] = useWorkOrderDetails();

  const crewListSelectOptions = () => {
    const employeeAuthor =
      inspectionAnswer.updatedByUser?.employeeId ||
      inspectionAnswer.createdByUser?.employeeId;
    const crews = workOrderDetails.workOrder.crewWorkOrders.map(
      (cwo) => cwo.crew
    );
    //consider only the crew of the creator of the answer
    const crew = crews.find((crew) =>
      crew.employeeCrews.find(
        (ec) => ec.employee.id === employeeAuthor && ec.isCrewLead
      )
    );
    if (!crew) {
      return noOptions;
    }
    const employees = crew.employeeCrews.filter((ec) =>
      question.options.roleId
        ? ec.role.id === question.options.roleId
        : question.options.isCrewLead
        ? ec.isCrewLead
        : true
    );
    const options = employees.length
      ? employees.map((option) => ({
          value: option?.employee?.id,
          label: `${option?.employee?.firstName}${
            option?.employee?.lastName ? ` ${option?.employee?.lastName}` : ""
          }`,
        }))
      : noOptions;
    return options;
  };

  switch (inspectionQuestionType) {
    case MULTIPLE_CHOICE:
      return (
        <div>
          <Select
            placeholder={
              <span className="text-muted">Select an answer...</span>
            }
            styles={style}
            className="flex-grow-1 border rounded my-2"
            options={question.optionsAvailable.map((option) => ({
              value: option.inspectionQuestionOption?.id,
              label: option.inspectionQuestionOption?.name,
              requiresExplanation: option.requiresExplanation,
            }))}
            closeMenuOnSelect={true}
            value={inputValue}
            onChange={(e) => setInputValue(e)}
          />
          {inputValue?.requiresExplanation ? (
            <FormGroup>
              <Label className="text-sm-left text-danger">
                Explanation required
              </Label>
              <Input
                maxLength="255"
                type="textarea"
                placeholder="Type explanation here..."
                value={explanation}
                onChange={(e) => setExplanation(e.target.value)}
              />
            </FormGroup>
          ) : null}
        </div>
      );
    case CREW_LIST:
      const optionsSelectCrewList = crewListSelectOptions();
      return (
        <div>
          <Select
            placeholder={
              <span className="text-muted">Select an answer...</span>
            }
            styles={style}
            className="flex-grow-1 border rounded my-2"
            options={optionsSelectCrewList}
            closeMenuOnSelect={true}
            value={inputValue}
            onChange={(e) => setInputValue(e)}
          />
        </div>
      );
    case SHORT_TEXT:
      return (
        <Input
          maxLength="255"
          type="text"
          placeholder="Type answer here..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      );
    case LONG_TEXT:
      return (
        <Input
          maxLength="65535"
          type="textarea"
          placeholder="Type answer here..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      );
    case NUMBER:
      return (
        <Input
          step={0.01}
          max={9999999999}
          min={0}
          type="number"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      );
    case DATE:
      return (
        <DayPicker
          className="date-picker bg-white rounded border d-flex justify-content-center"
          mode="single"
          defaultMonth={inputValue}
          selected={[inputValue]}
          modifiersClassNames={{
            selected: "my-selected",
            today: "my-today",
            range_start: "my-range_start",
            range_end: "my-range_end",
          }}
          onSelect={setInputValue}
        />
      );
    case CLOCKFACE:
      return (
        <div className="mt-3">
          <Clockface
            isEdit={true}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        </div>
      );
    case CLOCKFACE_ALIGNMENT_ENTRY:
      return (
        <div className="mt-3">
          <DimensionsClockfaceAlignmentEntry
            isEdit={true}
            inputValue={inputValue}
            setInputValue={setInputValue}
            withDimensions={false}
          />
        </div>
      );
    case DIMENSIONS_CLOCKFACE_ALIGNMENT_ENTRY:
      return (
        <div className="mt-3">
          <DimensionsClockfaceAlignmentEntry
            isEdit={true}
            inputValue={inputValue}
            setInputValue={setInputValue}
            withDimensions={true}
          />
        </div>
      );
    case AUTOPOPULATE_FORMULA:
      return (
        <Input
          step="any"
          max={9999999999}
          min={0}
          type="number"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      );
    default:
      return <span>{inputValue}</span>;
  }
};

export default AnswerInput;
