import axios from "axios";

import config from "../config/config";
import { apiHelper } from "../helpers/apiHelper";

export const employeeSafetyCertificationsApi = {
  getAll: async (params = {}) => {
    const queryParams = new URLSearchParams(params);
    const url = `/api/employee-safety-certifications?${queryParams.toString()}`;

    return axios(url, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "GET",
    })
      .then((response) => response.data)
      .catch((error) => apiHelper.handleError(error));
  },
  getSignedFile: async (id, data = {}) => {
    const url = `/api/employee-safety-certifications/${id}/signed-file`;
    return axios
      .get(url, {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        responseType: "blob",
      })
      .then((response) => {
        if (data.type === "blob") {
          return response.data;
        }

        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        const contentDisposition = response.headers["content-disposition"];
        const fileName = contentDisposition
          ? contentDisposition.split("filename=")[1].replace(/"/g, "")
          : "safety-certification.pdf";
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => apiHelper.handleError(error));
  },
};
