import { faBell, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";

import InformationModal from "../InformationModal";
import { changeOrderHelper } from "../../helpers/changeOrderHelper";
import { utilsHelper } from "../../helpers/utilsHelper";
import { useAuth } from "../../providers/authProvider";

const ChangeOrderAlert = () => {
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const onEmailCustomer = () => {
    //TODO: Send customer email
    return setInformationModal({
      isOpen: true,
      title: "Change Order",
      body: "Feature not available.",
    });
  };

  const changeOrders = workOrderDetails.workOrder?.changeOrders.filter(
    (value) => value.statusId !== changeOrderHelper.STATUS_INVOICED
  );

  return (
    <div>
      {changeOrders?.length > 0 &&
        changeOrders.map((changeOrder) => {
          return (
            <Card key={changeOrder.id}>
              <ListGroup>
                <ListGroupItem className="d-flex justify-content-between align-items-center p-0">
                  <Alert
                    className="flex-grow-1 mb-0 max-height-45 border-radius-bottom-none"
                    color={
                      changeOrder.statusId === changeOrderHelper.STATUS_CREATED
                        ? "warning"
                        : "info"
                    }
                  >
                    <div
                      className="alert-icon"
                      style={{ padding: "0.75rem 0.95rem" }}
                    >
                      <FontAwesomeIcon icon={faBell} fixedWidth />
                    </div>
                    <div className="alert-message text-left d-flex justify-content-between align-items-center">
                      <span className="font-weight-bold">
                        {changeOrder.statusId ===
                        changeOrderHelper.STATUS_CREATED
                          ? "New"
                          : ""}{" "}
                        Change Order
                      </span>
                      {!utilsHelper.isReadOnly(authContext) ? (
                        <div className="d-flex align-items-center">
                          <Badge
                            className="ml-1 rounded text-white border-white border"
                            color={
                              changeOrder.statusId ===
                              changeOrderHelper.STATUS_CREATED
                                ? "warning"
                                : "info"
                            }
                          >
                            {
                              changeOrderHelper.STATUS_LABEL[
                                changeOrder.statusId
                              ]
                            }
                          </Badge>
                          <Button
                            size="sm"
                            color={
                              changeOrder.statusId ===
                              changeOrderHelper.STATUS_CREATED
                                ? "warning"
                                : "info"
                            }
                            className="ml-1 rounded text-white border-white"
                            onClick={onEmailCustomer}
                          >
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              className="mr-2"
                            />
                            <span>Email Customer</span>
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  </Alert>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center py-2">
                  <span className="font-weight-bold">Submitted by</span>
                  <span>
                    {`${changeOrder.user?.firstName || "N/A"} ${
                      changeOrder.user?.lastName || "N/A"
                    }`}
                  </span>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center py-2">
                  <span className="font-weight-bold">Submitted at</span>
                  <span>
                    {moment(changeOrder.createdAt).format("MM/DD/YY hh:mm a")}
                  </span>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center py-2">
                  <span className="font-weight-bold">Work Description</span>
                  <span>{changeOrder.description}</span>
                </ListGroupItem>
              </ListGroup>
            </Card>
          );
        })}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : null}
    </div>
  );
};

export default ChangeOrderAlert;
