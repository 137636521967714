import moment from "moment";
import { utilsHelper } from "./utilsHelper";
import { ListGroup, ListGroupItem } from "reactstrap";

const employeeAlreadyAdded = (
  workOrderCrew,
  addedEmployees,
  employee,
  isNonJob
) =>
  (!isNonJob &&
    workOrderCrew &&
    workOrderCrew.count + (workOrderCrew.overageCount || 0) ===
      workOrderCrew.employees.length) ||
  (addedEmployees && addedEmployees.find((item) => item.id === employee.id));

const enabledAddEmployee = (workOrderCrew, employee) =>
  workOrderCrew &&
  workOrderCrew.count + (workOrderCrew.overageCount || 0) > 0 &&
  !workOrderCrew.employees.find((item) => item.id === employee.id);

const employeeConflicts = (
  workOrderStatuses,
  workOrder,
  employee,
  navigate
) => {
  if (workOrder) {
    const conflictingWorkOrders = utilsHelper.employeeHasConflict(
      workOrderStatuses,
      workOrder,
      (employee.employeeCrews || []).filter((ec) =>
        utilsHelper.isEmployeeCrewActiveOnDate(ec)
      )
    );
    if (conflictingWorkOrders?.length) {
      const list = conflictingWorkOrders.map((wo) => (
        <ListGroupItem
          key={wo.id}
          className="d-flex justify-content-between align-items-center py-2"
          tag="div"
        >
          <span
            className="text-link"
            onClick={() => navigate(`/work-orders/details/${wo.id}`)}
          >
            {wo.workOrderNumber || "Group WO"}
          </span>
          <span>{wo.customerName || "customer not specified"}</span>
        </ListGroupItem>
      ));
      const conflict = (
        <ListGroup>
          <ListGroupItem
            className="d-flex justify-content-center align-items-center py-2 bg-lighter font-weight-bold"
            tag="div"
          >
            {`${employee.firstName} ${
              employee.lastName || ""
            } is already assigned to`}
          </ListGroupItem>
          {list}
        </ListGroup>
      );
      return conflict;
    }
  }
};

const updateWorkOrderCrew = (workOrderCrew, employee) => {
  const updatedWorkOrderCrew = { ...workOrderCrew };
  updatedWorkOrderCrew.employees.push({
    ...employee,
    addedTime: moment().format(),
    isOverage: updatedWorkOrderCrew.employees.length >= workOrderCrew.count,
  });
  updatedWorkOrderCrew.overageCount = Math.max(
    updatedWorkOrderCrew.employees.length - updatedWorkOrderCrew.count,
    0
  );
  return updatedWorkOrderCrew;
};

const onAddCrewEmployee = ({
  //to load base crew and editing crew
  employee,
  asRole,
  isOverage = null,
  addedTime = moment().format(),
  workOrderCrews,
}) => {
  if (!workOrderCrews) {
    return;
  }

  const workOrderCrew = workOrderCrews.find(
    (woc) => parseInt(woc.roleId) === parseInt(asRole)
  );

  const addedEmployees = workOrderCrews.flatMap((woc) => woc.employees);

  if (
    (workOrderCrew &&
      workOrderCrew.count + (workOrderCrew.overageCount || 0) ===
        workOrderCrew.employees.length) ||
    (addedEmployees && addedEmployees.find((item) => item.id === employee.id))
  ) {
    return; //already added
  }

  if (
    workOrderCrew &&
    workOrderCrew.count + (workOrderCrew.overageCount || 0) > 0 &&
    !workOrderCrew.employees.find((item) => item.id === employee.id)
  ) {
    const updatedWorkOrderCrew = { ...workOrderCrew };
    updatedWorkOrderCrew.employees.push({
      ...employee,
      addedTime,
      isOverage:
        isOverage ||
        updatedWorkOrderCrew.employees.length >= workOrderCrew.count,
    });
    updatedWorkOrderCrew.overageCount = Math.max(
      updatedWorkOrderCrew.employees.length - updatedWorkOrderCrew.count,
      0
    );

    const index = workOrderCrews.findIndex(
      (woc) => parseInt(woc.roleId) === parseInt(asRole)
    );

    workOrderCrews.splice(index, 1, updatedWorkOrderCrew);
  }
};

const addOverageEmployeeCrews = ({
  //to load base crew and editing crew
  employeeCrews,
  shift,
  outage,
  workOrderId,
  currentWorkOrderCrews,
}) => {
  /*
    scenarios:
        1 - Overage role doesnt exist in the intial work order crews
            the role is added in the work order crews and the count is set all to overageCount
        2 - Overage role exists in the initial work order crews
            the overageCount for the corresponding work order crews is increased by the #employeeCrews with that role - current count
    */
  const roleNames = {};
  const overageRoles = Object.keys(
    employeeCrews
      .filter((ec) => {
        const currentWorkOrderCrew = currentWorkOrderCrews.find(
          (woc) => parseInt(woc.roleId) === parseInt(ec.roleId)
        );
        const employeeCrewCount = employeeCrews.filter(
          (ecs) => parseInt(ecs.roleId) === parseInt(ec.roleId)
        );
        return (
          !ec.disabledAt &&
          (ec.isOverage ||
            !currentWorkOrderCrew ||
            (currentWorkOrderCrew &&
              currentWorkOrderCrew.count < employeeCrewCount))
        );
      })
      .reduce((p, c) => {
        roleNames[c.roleId] = c.role.name;
        p[c.roleId] = true;
        return p;
      }, {})
  );
  //scenario 1: Overage role doesnt exist in the intial work order crews
  const roles = {};
  const items = overageRoles
    .filter(
      (overageRole) =>
        !currentWorkOrderCrews.find(
          (cwoc) => parseInt(cwoc.roleId) === parseInt(overageRole)
        )
    )
    .reduce((p, overageRole) => {
      p[overageRole] = employeeCrews
        .filter(
          (ec) =>
            !ec.disabledAt && parseInt(ec.roleId) === parseInt(overageRole)
        )
        .map((ec) => {
          roles[overageRole] = ec.role;
          return {
            ...ec.employee,
            addedTime: ec.addedTime || moment().format(),
            isOverage: true,
          };
        });
      return p;
    }, {});
  const overageWorkOrderCrews = Object.keys(items).map((roleId) => ({
    name: roleNames[roleId],
    count: 0,
    overageCount: items[roleId].length,
    outage,
    roleId,
    role: roles[roleId],
    shift,
    workOrderId,
    employees: items[roleId],
  }));
  //scenario 2: Overage role exists in the initial work order crews
  overageRoles
    .filter((overageRole) =>
      currentWorkOrderCrews.find(
        (cwoc) => parseInt(cwoc.roleId) === parseInt(overageRole)
      )
    )
    .forEach((overageRole) => {
      const currentWorkOrderCrew = currentWorkOrderCrews.find(
        (cwoc) => parseInt(cwoc.roleId) === parseInt(overageRole)
      );
      const currentEmployeeCrews = employeeCrews.filter(
        (ec) => parseInt(ec.roleId) === parseInt(overageRole)
      );
      currentWorkOrderCrew.overageCount =
        currentEmployeeCrews.length - currentWorkOrderCrew.count;
      currentWorkOrderCrew.employees = currentEmployeeCrews.map((ec) => ({
        ...ec.employee,
        addedTime: ec.employee.addedTime || moment().format(),
        isOverage: ec.isOverage,
      }));
    });
  return [...currentWorkOrderCrews, ...overageWorkOrderCrews];
};

export const crewManagementHelper = {
  addOverageEmployeeCrews,
  onAddCrewEmployee,
  employeeAlreadyAdded,
  enabledAddEmployee,
  updateWorkOrderCrew,
  employeeConflicts,
};
