import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Loader from "../../../Loader";
import AnswerInput from "./AnswerInput";
import { evaluate } from "mathjs";

const AUTOPOPULATE_FORMULA = 15;

const EditAnswerCalculationModal = ({
  onSubmit,
  onClose,
  inspectionQuestionType,
  question,
  answers,
}) => {
  const inspectionAnswer = question.inspectionAnswers[0];

  const [loading, setLoading] = useState(false);
  const [explanation, setExplanation] = useState(
    inspectionAnswer.explanation || ""
  );
  const [inputValue, setInputValue] = useState();

  const calculateFormula = (formula, answers) => {
    if (!formula) return "";

    const getVariableValue = (variable) => {
      if ("constant" in variable) {
        return variable.constant;
      } else if ("questionId" in variable) {
        const answer = answers.find(
          (ans) => ans.inspectionQuestionId === variable.questionId
        );
        return answer?.content ?? 0;
      }
      return 0;
    };

    const operationWithValues = formula.operation.replace(/(\d+)/g, (match) => {
      const index = parseInt(match, 10);
      return getVariableValue(formula.variables[index]);
    });

    try {
      const result = evaluate(operationWithValues);
      return parseFloat(result.toFixed(4));
    } catch (err) {
      return "";
    }
  };

  useEffect(() => {
    if (inspectionQuestionType === AUTOPOPULATE_FORMULA) {
      const formula = question?.options.formula.calculation;
      setInputValue(calculateFormula(formula, answers));
    } else {
      setInputValue(inspectionAnswer.content);
    }
  }, [
    inspectionAnswer.content,
    answers,
    question,
    inspectionQuestionType,
    question?.options?.formula?.calculation,
  ]);

  const doSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    onSubmit({
      ...inspectionAnswer,
      content: inputValue,
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <form onSubmit={doSubmit}>
        <ModalHeader close={closeBtn}>Result of the operation</ModalHeader>
        <ModalBody>
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <div>
              <i>{question.content}</i>
              <div className="mt-2">
                <AnswerInput
                  inspectionQuestionType={inspectionQuestionType}
                  question={question}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  explanation={explanation}
                  setExplanation={setExplanation}
                />
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          <Button disabled={loading} color="primary" type="submit">
            Save Result
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditAnswerCalculationModal;
